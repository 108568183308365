import React from "react";

import screenHeader from "../assets/images/screenHeader.png";
import headerLogo from "../assets/images/headerLogo.png";

import "../styles/screenHeaderBar-styles.css";

const ScreenHeaderBar = () => {
  return (
    <>
      <img src={screenHeader} alt="" className="screen-header" />
      <img src={headerLogo} alt="" className="logo" />
    </>
  );
};

export default ScreenHeaderBar;
