import React from "react";

import app_qr_code from "../assets/images/home_qr_code.webp";
import buyStarsComQrCode from "../assets/images/buystars.com_home_qr_code.webp";
import line from "../assets/images/line.webp";
import { isBuyStarsCom } from "../constants/htmlStrings";

import "../styles/qrCode-styles.css";

const QRCode = () => {
  const QrCode = isBuyStarsCom ? buyStarsComQrCode : app_qr_code;
  return (
    <div  className="qr-container">
      <img src={line} alt="" className="qr-line"/>
      <div className="qr-image-container">
        {/* <img
          src={rectangle}
          alt=""
          className="blue-rectangle"
        /> */}
        <img
          src={QrCode}
          alt=""
          className="qr-image"
        />
      </div>
      <div className="scan-instruction-container">
        <div
          className="scan-text"
        >
          Scan QR code to<br />
          download Android app
        </div>
      </div>
    </div>
  );
};

export default QRCode;
