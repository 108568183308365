import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import QRCodeLP1 from "../components/QRCodeLP1";


import logos from "../assets/images/logo-black.png";
import download from "../assets/images/download.webp";
import desktopPlayers from "../assets/images/desktop-DK.webp";
import mobilePlayers from "../assets/images/mobile-DK.webp";
import rotate from "../assets/images/rotate.png";
import appleLogo from "../assets/images/apple-logo.png";
import { isAppleDevice, isSafari } from "../utility/DownloadApkUtils";

import {
  websiteMetaTagDescription,
  websiteTitle,
} from "../constants/htmlStrings";
import { appDownloadLink } from "../constantsLP1/htmlStrings";
import { googleAnalyticsTrack } from "../utility/common";

import "../styles/home-styles.css";

const LP4 = () => {

  const handleDownloadButtonClick = () => {
    googleAnalyticsTrack(true);
    if (isAppleDevice || isSafari) window.open("https://apps.apple.com/in/app/buystars/id1620171125", "_blank");
  };

  useEffect(() => {
    googleAnalyticsTrack();
  }, []);
  const downloadLogo = isAppleDevice || isSafari ? appleLogo : download
  return (
    <>
      <div className="mobile-landscape">
        <div className="text-style">Please Rotate Your Device</div>
        <img src={rotate} alt="rotate" className="rotate-icon-style" />
      </div>
      <div className="main-container">
        <Helmet>
          <title>{websiteTitle}</title>
          <meta name="description" content={websiteMetaTagDescription} />
        </Helmet>
        <div className="container-lp1">
          <div className="blur-download-button-container" />
          <div className="download-button-container">
            <div className="bottom-mobile-text">Get &#8377;750 Real Cash Bonus</div>
            <a
              href={appDownloadLink}
              id="mobile-download-button"
              onClick={handleDownloadButtonClick}
              download
              className="download-link"
            >
              <div className="download-button-lp1">
                <div className="download-icon-container">
                  <img src={downloadLogo} alt="" className="download-icon" />
                </div>
                <div className="download-text">DOWNLOAD APP</div>
              </div>
            </a>
          </div>
          <div className="sub-container">
            <img alt="logo" src={logos} className="logos-lp1" />
            <div className="title-and-button-lp1">
              <div className="mobile-title-lp1">
                <div className="top-text">Play Fantasy Cricket</div>
                <div className="bottom-text">and Win Exciting Prizes</div>
              </div>
              <div className="desktop-title-lp1">
                <div className="top-text-lp1">
                  Buy and trade players,
                  <br />
                  create teams to join contests,
                </div>
                <div className="bottom-text-lp1">Win Lakhs!</div>
              </div>
            </div>
            <img src={mobilePlayers} alt="" className="mobile-players-lp1" />
            <QRCodeLP1 />
          </div>
          <img src={desktopPlayers} alt="" className="desktop-players-lp1" />
        </div>
        <div className="footer-container">
          <div className="footer-nav-lp1">
            <div className="nav-button">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/terms-of-service" className="nav-link">
                Terms of Service
              </Link>
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/privacy-policy" className="nav-link">
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="copyright-lp1">
            Copyright &copy; 2022 |&nbsp;
            <div className="copyright-company">Buystars.com</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LP4;
