/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import QRCode from "../components/QRCode";

import download from "../assets/images/android-icon.webp";
import desktopPlayers from "../assets/images/desktop-DK.webp";
import mobilePlayers from "../assets/images/lp7_mobile.webp";
import rotate from "../assets/images/rotate.png";
import winDailyImage from "../assets/images/lp6_middle.webp";
import trustedPartnerDesktop from "../assets/images/desktop-trusted-partner.webp";
import appleLogo from "../assets/images/apple-logo.png";
import { isAppleDevice, isSafari } from "../utility/DownloadApkUtils";
import {
  PAGE_NAME,
  websiteMetaTagDescription,
  websiteTitle,
} from "../constants/htmlStrings";
import { googleAnalyticsTrack, branchinitialization } from "../utility/common";

import "../styles/home-styles.css";
import { downloadApk } from "../utility/DownloadApkUtils";

const LP7 = () => {
  let url = window.location.href;
  const isNewVersion = Date.now() % 2 === 0;

  const [branchLink, setBranchLink] = useState("");
  const handleDownloadButtonClick = () => {
    googleAnalyticsTrack(true, isNewVersion);
  };

  useEffect(() => {
    googleAnalyticsTrack();
    branchinitialization(process.env.REACT_APP_BRANCH_FANTASY_KEY);
  }, []);

  console.log("Branch link", branchLink)

  useEffect(() => {
    setBranchLink(downloadApk(PAGE_NAME.LP7));
  }, [url]);
  const downloadLogo = isAppleDevice || isSafari ? appleLogo : download;
  return (
    <>
      <div className="mobile-landscape">
        <div className="text-style">Please Rotate Your Device</div>
        <img src={rotate} alt="rotate" className="rotate-icon-style" />
      </div>
      <div className="lp6-main-container">
        <Helmet>
          <title>{websiteTitle}</title>
          <meta name="description" content={websiteMetaTagDescription} />
        </Helmet>
        <div className="container-lp6">
          <div className="blur-download-button-container" />
          <div className="download-button-container-lp6">
            <div className="bottom-mobile-text-lp6">
              Download Now to avail the Offer!
            </div>
            <div className="download-empty-container" />
            <a
              href={branchLink}
              id="mobile-download-button"
              onClick={handleDownloadButtonClick}
              download
              className="download-link"
            >
              <div className="download-button">
                <img src={downloadLogo} alt="" className="download-icon" />
                <div className="download-text">DOWNLOAD APP</div>
              </div>
            </a>
          </div>
          <div className="sub-container">
            <img src={mobilePlayers} alt="" className="mobile-players-l6" />
            <QRCode />
          </div>
          <img src={desktopPlayers} alt="" className="desktop-players" />
        </div>
        <div className="social-container">
          <div className="trusted-container">
          <img src={winDailyImage} alt="" className="win-daily-image" />
            
            <img
              src={trustedPartnerDesktop}
              alt=""
              className="desktop-trusted-image"
            />
          </div>
        </div>
        <div className="lp6-footer-container">
          <div className="footer-nav">
            <div className="nav-button">
              <Link to="/about-us" className="lp6-nav-link">
                About Us
              </Link>
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/terms-of-service" className="lp6-nav-link">
                Terms of Service
              </Link>
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/privacy-policy" className="lp6-nav-link">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LP7;
