/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import QRCode from "../components/QRCode";

import logos from "../assets/images/logo-black.png";
import aifgLogo from "../assets/images/aigf-logo.png";
import download from "../assets/images/android-icon.webp";
import desktopPlayers from "../assets/images/desktop-DK.webp";
import mobilePlayers from "../assets/images/mobile-DK.webp";
import rotate from "../assets/images/rotate.png";
import section3Image from "../assets/images/section3-background.webp";
import trustedPartner from "../assets/images/mobile-trusted-partner.webp";
import trustedPartnerDesktop from "../assets/images/desktop-trusted-partner.webp";
import appleLogo from "../assets/images/apple-logo.png";
import { isAppleDevice, isSafari } from "../utility/DownloadApkUtils";
import {
    copyRightText,
    CUSTOMER_REVIEW,
    PAGE_NAME,
    SECTION_DATA,
    SOCIAL_MEDIA,
    websiteMetaTagDescription,
    websiteTitle,
} from "../constants/htmlStrings";
import { googleAnalyticsTrack, branchinitialization } from "../utility/common";

import "../styles/home-styles.css";

const ForceUpdateMigration = () => {
    let url = window.location.href;
    const isNewVersion = true

    const handleDownloadButtonClick = () => {
        googleAnalyticsTrack(true, isNewVersion);
    };

    useEffect(() => {
        googleAnalyticsTrack();
    }, []);

    const downloadLogo = isAppleDevice || isSafari ? appleLogo : download;
    return (
        <>
            <div className="mobile-landscape">
                <div className="text-style">Please Rotate Your Device</div>
                <img src={rotate} alt="rotate" className="rotate-icon-style" />
            </div>
            <div className="main-container">
                <Helmet>
                    <title>{websiteTitle}</title>
                    <meta name="description" content={websiteMetaTagDescription} />
                </Helmet>
                <div className="container">
                    <div className="blur-download-button-container" />
                    <div className="download-button-container">
                        <div className="bottom-mobile-text">
                            Get &#8377;750 Real Cash Bonus
                        </div>
                        <div className="download-empty-container" />
                        <a
                            href={"https://s3.ap-south-1.amazonaws.com/cdn.buystars.com/APK/TradingAndFantasy/BuystarsProTF-Upgrade.apk"}
                            id="mobile-download-button"
                            onClick={handleDownloadButtonClick}
                            download
                            className="download-link"
                        >
                            <div className="download-button">
                                <img src={downloadLogo} alt="" className="download-icon" />
                                <div className="download-text">DOWNLOAD APP</div>
                            </div>
                        </a>
                    </div>
                    <div className="sub-container">
                        <img alt="logo" src={logos} className="logos" />
                        <div className="title-and-button">
                            <div className="title">
                                <div className="top-text">Play Fantasy Cricket</div>
                                <div className="bottom-text">and Win Exciting Prizes</div>
                            </div>
                        </div>
                        <img src={mobilePlayers} alt="" className="mobile-players" />
                        <QRCode />
                    </div>
                    <img src={desktopPlayers} alt="" className="desktop-players" />
                    {/* <img src={line} alt="" className="qr-line" /> */}
                </div>

                {/* Section 3 */}
                <div className="desktop-section2-container">
                    <div className="section-sub-container">
                        <div className="section-title-text">{SECTION_DATA[0].title}</div>
                        <div className="section-description-text">
                            {SECTION_DATA[0].description}
                        </div>
                    </div>
                    <div className="desktop-empty-container" />
                    <img
                        src={section3Image}
                        alt=""
                        className="section-mobile-image-trophy"
                    />
                </div>

                {/* Section 4 */}
                <div className="desktop-section4-container">
                    {CUSTOMER_REVIEW.map((review) => (
                        <div className="section-sub-container">
                            <div className="section4-title-text">{review.tile}</div>
                            <div className="section4-description-text">{review.subtitle}</div>
                        </div>
                    ))}
                </div>
                <div className="desktop-section4-new-container" />

                <div className="trusted-divider" />
                <div className="social-container">
                    <div className="trusted-container">
                        <div className="follow-partner-text">Trusted By</div>
                        <img src={trustedPartner} alt="" className="trusted-image" />
                        <img
                            src={trustedPartnerDesktop}
                            alt=""
                            className="desktop-trusted-image"
                        />
                    </div>
                    <div className="trusted-container">
                        <div className="follow-partner-text">Member Of</div>
                        <img src={aifgLogo} alt="" className="trusted-image" />
                        <img src={aifgLogo} alt="" className="desktop-aigf-image" />
                    </div>
                    <div className="trusted-container">
                        <div className="follow-partner-text">Follow us on</div>
                        <div className="icon-container">
                            {SOCIAL_MEDIA.map((media) => (
                                <a href={media.link} target="_blank" rel="noreferrer">
                                    <img
                                        src={media.icon}
                                        alt={media.name}
                                        className={media.className}
                                    />
                                </a>
                            ))}
                        </div>
                        <div className="desktop-support">
                            For support email us on{" "}
                            <a href="mailto:support@buystars.com" className="support-link">
                                support@buystars.com
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-container">
                    <div className="footer-nav">
                        <div className="nav-button">
                            <Link to="/about-us" className="nav-link">
                                About Us
                            </Link>
                        </div>
                        <div className="border"></div>
                        <div className="nav-button">
                            <Link to="/terms-of-service" className="nav-link">
                                Terms of Service
                            </Link>
                        </div>
                        <div className="border"></div>
                        <div className="nav-button">
                            <Link to="/privacy-policy" className="nav-link">
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                    <div className="mobile-support">
                        For support email us on{" "}
                        <a href="mailto:support@buystars.com" className="support-link">
                            support@buystars.com
                        </a>
                    </div>
                    <div className="copyright">
                        Copyright &copy; 2022 |&nbsp;
                        <div className="copyright-company">{copyRightText}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForceUpdateMigration;
