import ReactGA from "react-ga";
import branch from "branch-sdk";

export const googleAnalyticsTrackId = "G-H36S9B3E62";
export const googleAnalyticsTrackId2 = "AW-16447439167";

export const googleAnalyticsTrack = (
  isDownloadButtonClicked = false,
  newVersion = true
) => {
  const version = newVersion ? "new" : "old";
  ReactGA.pageview(window.location.pathname + window.location.search);
  if (isDownloadButtonClicked) {
    ReactGA.ga("event", "conversion", version, {
      send_to: "AW-10861270380/H8KPCOOd1KkDEOyqh7so",
    });
    ReactGA.ga("event", "conversion", version, {
      send_to: "AW-16447439167/Bp4TCJyVlY8ZEL-C4KI9",
    });
  }
};

export function branchinitialization(branchkey) {
  branch.init(branchkey, function (err, data) {
    console.log("Branch Error ", err);
    console.log("Branch Data ", data);
  });
}
