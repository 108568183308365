/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import QRCodeLP1 from "../components/QRCodeLP1";

import logos from "../assets/images/logo-black.png";
import download from "../assets/images/download.webp";
import desktopPlayers from "../assets/images/desktop-BTL.webp";
import mobilePlayers from "../assets/images/mobile-BTL.webp";
import rotate from "../assets/images/rotate.png";
import appleLogo from "../assets/images/apple-logo.png";
import { isAppleDevice, isSafari } from "../utility/DownloadApkUtils";

import {
  websiteMetaTagDescription,
  websiteTitle,
  copyRightText,
  PAGE_NAME,
} from "../constants/htmlStrings";
import { googleAnalyticsTrack, branchinitialization } from "../utility/common";
import { downloadApk } from "../utility/DownloadApkUtils";

import "../styles/home-styles.css";

const LP5 = () => {
  let url = window.location.href;
  const isNewVersion = Date.now() % 2 === 0;

  const [branchLink, setBranchLink] = useState("");
  const handleDownloadButtonClick = () => {
    googleAnalyticsTrack(true, isNewVersion);
  };

  useEffect(() => {
    googleAnalyticsTrack();
    branchinitialization(process.env.REACT_APP_BRANCH_FANTASY_KEY);
  }, []);

  useEffect(() => {
  setBranchLink(downloadApk(PAGE_NAME.LP5));
  }, [url]);
  const downloadLogo = isAppleDevice || isSafari ? appleLogo : download
  return (
    <>
      <div className="mobile-landscape">
        <div className="text-style">Please Rotate Your Device</div>
        <img src={rotate} alt="rotate" className="rotate-icon-style" />
      </div>
      <div className="main-container">
        <Helmet>
          <title>{websiteTitle}</title>
          <meta name="description" content={websiteMetaTagDescription} />
        </Helmet>
        <div className="container-lp5">
          <div className="blur-download-button-container" />
          <div className="download-button-container">
          <div className="bottom-mobile-text">Get &#8377;750 Real Cash Bonus</div>
            <div className="download-empty-container" />
            <a
              href={branchLink}
              id="mobile-download-button"
              onClick={handleDownloadButtonClick}
              download
              className="download-link"
            >
              <div className="download-button-lp1">
                <div className="download-icon-container">
                  <img src={downloadLogo} alt="" className="download-icon" />
                </div>
                <div className="download-text">DOWNLOAD APP</div>
              </div>
            </a>
          </div>
          <div className="sub-container">
            <img alt="logo" src={logos} className="logos-lp1" />
            <div className="title-and-button-lp1">
              <div className="mobile-title-lp1">
              <div className="top-text">Play Fantasy Cricket</div>
                <div className="bottom-text">and Win Exciting Prizes</div>
              </div>
              <div className="desktop-title-lp1">
                <div className="top-text-lp1">
                  The new destination,
                  <br />
                  for all cricket fans
                </div>
                <div className="bottom-text-lp1">Win Lakhs!</div>
              </div>
            </div>
            <img src={mobilePlayers} alt="" className="mobile-players-lp5" />
            {/* <img src={qrCode} alt="" className="qrCode" /> */}
            <QRCodeLP1 />
          </div>
          <img src={desktopPlayers} alt="" className="desktop-players-lp5" />
        </div>
        
        <div className="footer-container">
          <div className="footer-nav-lp1">
            <div className="nav-button">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
              {/* <a target="_blank" href="https://buystars-qa.s3.ap-south-1.amazonaws.com/aboutUs.html" className="nav-link" rel="noreferrer">
              About Us
            </a> */}
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/terms-of-service" className="nav-link">
                Terms of Service
              </Link>
              {/* <a
              className="nav-link"
              href="https://buystars-policies.s3.ap-south-1.amazonaws.com/termsOfUse.html"
              rel="noreferrer"
              target="_blank"
            >
              Terms of Service
            </a> */}
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/privacy-policy" className="nav-link">
                Privacy Policy
              </Link>
              {/* <a
              className="nav-link"
              href="https://buystars-policies.s3.ap-south-1.amazonaws.com/privacyPolicy.html"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a> */}
            </div>
          </div>
          <div className="copyright-lp1">
            Copyright &copy; 2022 |&nbsp;
            <div className="copyright-company">{copyRightText}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LP5;