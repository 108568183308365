import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Line } from "rc-progress";

import QRCode from "../components/QRCode";

import logos from "../assets/images/logo-black.png";
import download from "../assets/images/download.webp";
import desktopPlayers from "../assets/images/desktop-DK.webp";
import mobilePlayers from "../assets/images/mobile-DK.webp";
import rotate from "../assets/images/rotate.png";
import appleLogo from "../assets/images/apple-logo.png";
import { isAppleDevice, isSafari } from "../utility/DownloadApkUtils";
import {
  websiteMetaTagDescription,
  websiteTitle,
  copyRightText,
  PAGE_NAME,
} from "../constants/htmlStrings";
import { googleAnalyticsTrack, branchinitialization } from "../utility/common";

import "../styles/home-styles.css";
import { downloadApk } from "../utility/DownloadApkUtils";

const LP2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const footerRef = useRef(null);
  const isNewVersion = Date.now() % 2 === 0;

  const scrollToRef = () => {
    const footerTop = footerRef.current.offsetTop;
    window.scrollTo({
      top: footerTop - window.innerHeight,
      behavior: "smooth",
    });
  };

  const history = useNavigate()

  useEffect(() => {
    googleAnalyticsTrack();
    branchinitialization(process.env.REACT_APP_BRANCH_TRADE_FANTASY_KEY);
  }, []);

  async function handleDownloadButtonClick() {
    if (!isLoading) {
      scrollToRef();
      googleAnalyticsTrack(true, isNewVersion);
      if (isAppleDevice || isSafari)
        window.open("https://apps.apple.com/in/app/buystars/id1620171125", "_blank");
      else downloadApk(PAGE_NAME.LP2, setIsLoading, setProgress);
    }
  }

  console.log(progress);
  const downloadLogo = isAppleDevice || isSafari ? appleLogo : download
  return (
    <>
      <div className="mobile-landscape">
        <div className="text-style">Please Rotate Your Device</div>
        <img src={rotate} alt="rotate" className="rotate-icon-style" />
      </div>
      <div className="main-container">
        <Helmet>
          <title>{websiteTitle}</title>
          <meta name="description" content={websiteMetaTagDescription} />
        </Helmet>
        <div className="container-lp1">
          <div className="blur-download-button-container" />
          <div className="download-button-container">
            <div className="bottom-mobile-text">Get &#8377;750 Real Cash Bonus</div>
            <div
              className="download-button-lp1"
              id="mobile-download-button"
              onClick={handleDownloadButtonClick}
              style={{ cursor: "pointer" }}
            >
              <div className="download-icon-container">
                <img src={downloadLogo} alt="" className="download-icon" />
              </div>
              {!isLoading ? (
                <div className="download-text">DOWNLOAD APP</div>
              ) : (
                <div className="download-text">DOWNLOADING</div>
              )}
            </div>
          </div>
          <div className="sub-container">
            <img alt="logo" src={logos} className="logos-lp1" />
            <div className="title-and-button">
              <div className="title">
                <div className="top-text">Play Fantasy Cricket</div>
                <div className="bottom-text">and Win Exciting Prizes</div>
              </div>
              <div>
                {isLoading && (
                  <Line
                    style={{ height: "0.5rem" }}
                    percent={progress}
                    strokeWidth="4"
                    strokeColor="#1467b5"
                  />
                )}
              </div>
            </div>
            <img src={mobilePlayers} alt="" className="mobile-players-lp1" />
            <QRCode />
          </div>
          <img src={desktopPlayers} alt="" className="desktop-players" />
        </div>

        <div className="footer-container" ref={footerRef}>
          <div className="footer-nav">
            <div className="nav-button">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/terms-of-service" className="nav-link">
                Terms of Service
              </Link>
            </div>
            <div className="border"></div>
            <div className="nav-button">
              <Link to="/privacy-policy" className="nav-link">
                Privacy Policy
              </Link>
            </div>
          </div>
          <div className="copyright">
            Copyright &copy; 2022 |&nbsp;
            <div className="copyright-company">{copyRightText}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LP2;